import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";

export interface IConfig {
  open?: boolean;
  message?: string;
  severity?: AlertColor;
}

export interface IContext {
  openSnackbar: (config: IConfig) => void;
}

interface ISnackbarProvider {
  children: any;
  duration?: number;
}

const Context = React.createContext({} as IContext);

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// eslint-disable-next-line react/require-default-props
const SnackbarProvider: React.FC<ISnackbarProvider> = ({
  children,
  duration = 3000,
}) => {
  const [config, setConfig] = React.useState<IConfig>({
    open: false,
    message: "",
    severity: "success",
  });

  const openSnackbar = React.useCallback((options: IConfig) => {
    setConfig({ ...options, open: true });
  }, []);

  const closeSnackbar = React.useCallback(() => {
    setConfig((state) => ({ ...state, open: false }));
  }, []);

  const value = React.useMemo(() => ({ openSnackbar }), [openSnackbar]);

  return (
    <Context.Provider value={value}>
      {children}
      <Snackbar
        open={config.open}
        autoHideDuration={duration}
        onClose={closeSnackbar}
        message={config.message}
      >
        <Alert
          onClose={closeSnackbar}
          severity={config?.severity}
          sx={{ width: "100%" }}
        >
          {config.message}
        </Alert>
      </Snackbar>
    </Context.Provider>
  );
};

export const useSnackbarConsumer = (): IContext =>
  React.useContext<IContext>(Context);

export const { Consumer } = Context;

export default SnackbarProvider;
